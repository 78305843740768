// src/pages/Categories.tsx
import React, { useEffect, useState } from "react";
import Category from "../components/Category";

interface CategoryType {
  id: number;
  name: string;
  description: string;
}

const Categories: React.FC = () => {
  const [categories, setCategories] = useState<CategoryType[]>([]);

  useEffect(() => {
    fetch("data/categories.json")
      .then((response) => response.json())
      .then((data) => setCategories(data));
  }, []);

  return (
    <>
      <h1>Categories</h1>
      <div className="d-flex flex-wrap">
        {categories.map((category) => (
          <Category
            key={category.id}
            id={category.id}
            name={category.name}
            description={category.description}
          />
        ))}
      </div>
    </>
  );
};

export default Categories;
