import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSessionContext } from "../contexts/SessionContext";
import { useSiteContext } from "../contexts/SiteContext";
import Property from "../components/Property";

interface Property {
  id: number;
  name: string;
  mlsId: string;
  userId: number;
  // add other fields
}

const Properties: React.FC = () => {
  const { userId } = useSiteContext();
  const { session, setMlsId } = useSessionContext();
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("/data/properties.json")
      .then((res) => res.json())
      .then((data) => {
        const userProperties = data.filter(
          (prop: Property) => prop.userId === userId
        );
        setProperties(userProperties);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
        setLoading(false);
      });
  }, [userId]);

  const handleContinueWithoutProperty = () => {
    setMlsId(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h1>Your Properties</h1>
      <div>
        <p>User ID: {userId}</p> {/* Display the user ID */}
        <p>Session ID: {session.sessionId}</p> {/* Display the session ID */}
      </div>
      <div>
        <Link to="/select-layout" onClick={handleContinueWithoutProperty}>
          Continue without selecting a property
        </Link>
      </div>
      {properties.map((property) => (
        <Property key={property.id} property={property} />
      ))}
    </>
  );
};

export default Properties;
