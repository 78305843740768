import React, { createContext, useState, useContext } from "react";

interface SiteContextProps {
  isAuthenticated: boolean;
  userId: number | null;
  userName: string;
  title: string;
  setAuthInfo: (
    isAuthenticated: boolean,
    userId: number | null,
    userName: string,
    title: string
  ) => void;
}

const SiteContext = createContext<SiteContextProps | undefined>(undefined);

export const useSiteContext = () => {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error("useSiteContext must be used within a SiteContextProvider");
  }
  return context;
};

interface SiteContextProviderProps {
  children: React.ReactNode;
}

export const SiteContextProvider = ({ children }: SiteContextProviderProps) => {
  const [siteState, setSiteState] = useState<{
    isAuthenticated: boolean;
    userId: number | null;
    userName: string;
    title: string;
  }>({
    isAuthenticated: false,
    userId: 1, //change it to null when login is added
    userName: "",
    title: "",
  });

  const setAuthInfo = (
    isAuthenticated: boolean,
    userId: number | null,
    userName: string,
    title: string
  ) => {
    setSiteState({
      isAuthenticated,
      userId,
      userName,
      title,
    });
  };

  return (
    <SiteContext.Provider
      value={{
        isAuthenticated: siteState.isAuthenticated,
        userId: siteState.userId,
        userName: siteState.userName,
        title: siteState.title,
        setAuthInfo,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
