// Property.tsx
import React from "react";
import { Link } from "react-router-dom";
import { useSessionContext } from "../contexts/SessionContext";

interface PropertyProps {
  property: {
    id: number;
    name: string;
    mlsId: string;
    // other fields...
  };
}

const Property: React.FC<PropertyProps> = ({ property }) => {
  const { setMlsId } = useSessionContext();

  const handlePropertyClick = () => {
    setMlsId(property.mlsId);
  };

  return (
    <div>
      <h3>{property.name}</h3>
      {/* other property details */}
      <Link to="/select-layout" onClick={handlePropertyClick}>
        Select Layout
      </Link>
    </div>
  );
};

export default Property;
