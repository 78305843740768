import React from "react";
import { Link } from "react-router-dom";

interface CategoryProps {
  id: number;
  name: string;
  description: string;
}

const Category: React.FC<CategoryProps> = ({ id, name, description }) => {
  return (
    <>
      <div className="col mb-5 pe-5">
        <div className="card h-100">
          <img
            className="card-img-top"
            src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
            alt="..."
          />
          <div className="card-body p-4">
            <div className="text-center">
              <h5 className="fw-bolder">{name}</h5>
              {description}
            </div>
          </div>
          <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
            <div className="text-center">
              <Link
                to={`/products/${id}`}
                className="btn btn-outline-dark mt-auto"
              >
                View Products
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
