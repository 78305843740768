import React from "react";
import { useNavigate } from "react-router-dom";

const Proof = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/cart");
  };

  return (
    <>
      <div className="row">
        <div className="col-6">Proof</div>
        <div className="col-6 text-end">
          <button onClick={handleNext} style={{ marginTop: "20px" }}>
            Next
          </button>
        </div>
      </div>
      <img
        src="https://admin.mymarketingmatters.com/Elevate.asp?key=cac42a3d-0982-46e7-af35-d22342d4de9e&action=thumbnail&iid=49820"
        alt="page 1"
        style={{ width: "500px" }}
      />
      <img
        src="https://admin.mymarketingmatters.com/Elevate.asp?key=cac42a3d-0982-46e7-af35-d22342d4de9e&action=thumbnail&iid=49822"
        alt="page 2"
        style={{ width: "500px" }}
      />
      <div className="pt-5">Options show here (to be built by AlphaSquad)</div>
    </>
  );
};

export default Proof;
