import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Elevate = () => {
  // Array of URLs to simulate navigating through different pages
  const urls = [
    "https://elevate.mymarketingmatters.com/frontends/1c625f104bc634d1782ae8b76d18d6ec/active/?script_id=12&api_key=cc6954e0-b937-11ed-a576-17e515a33623&instanceId=49820&userAccessToken=4751",
    "https://elevate.mymarketingmatters.com/frontends/1c625f104bc634d1782ae8b76d18d6ec/active/?script_id=12&api_key=cc6954e0-b937-11ed-a576-17e515a33623&instanceId=49822&userAccessToken=4751",
  ];

  // State to keep track of the current URL index
  const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
  const navigate = useNavigate();

  // Function to go to the next URL
  const handleNext = () => {
    // Check if the next index reaches the end of the array
    if (currentUrlIndex + 1 === urls.length) {
      // Navigate to '/proof' using React Router
      navigate("/proof");
    } else {
      // Otherwise, update the current URL index
      setCurrentUrlIndex(currentUrlIndex + 1);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-6">Proof</div>
        <div className="col-6 text-end">
          <button onClick={handleNext} style={{ marginTop: "20px" }}>
            Next
          </button>
        </div>
      </div>
      {/* iframe to display the current URL */}
      <iframe
        src={urls[currentUrlIndex]}
        style={{ width: "100%", height: "750px", border: "none" }}
        title="Edit your design"
      ></iframe>
    </>
  );
};

export default Elevate;
