import React from "react";

const Cart = () => {
  return (
    <>
      <h1>Cart</h1>
      <div>Your items show here.</div>
    </>
  );
};

export default Cart;
