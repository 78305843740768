import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  return (
    <div>
      <div className="mt-4 p-5 bg-primary text-white rounded">
        <h1>Welcome to Our My Marketing Matters!</h1>
        <p>
          This is a simple hero unit, a simple jumbotron-style component for
          calling extra attention to featured content or information.
        </p>
        <p>
          <Link to="/categories">
            <Button variant="primary">Explore Categories</Button>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Home;
