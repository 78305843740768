import React, { createContext, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface Session {
  sessionId: string;
  productId: number | null;
  mlsId?: string | null;
}

interface SessionContextProps {
  session: Session;
  setProductId: (id: number) => void;
  setMlsId: (id: string | null) => void;
}

const SessionContext = createContext<SessionContextProps | undefined>(
  undefined
);

export const useSessionContext = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};

export const SessionProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [session, setSession] = useState<Session>({
    sessionId: uuidv4(),
    productId: null,
    mlsId: null,
  });

  const resetSession = () => {
    setSession({
      sessionId: uuidv4(),
      productId: null,
      mlsId: null,
    });
  };

  const setProductId = (id: number) => {
    resetSession();
    setSession({ ...session, productId: id });
  };

  const setMlsId = (id: string | null) => {
    setSession({ ...session, mlsId: id });
  };

  return (
    <SessionContext.Provider value={{ session, setProductId, setMlsId }}>
      {children}
    </SessionContext.Provider>
  );
};
