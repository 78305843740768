import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { SiteContextProvider, useSiteContext } from "./contexts/SiteContext";
import "./App.css";
import "./Custom.css";
import Home from "./pages/Home";
import Categories from "./pages/Categories";
import Properties from "./pages/Properties";
import SelectLayout from "./pages/SelectLayout";
import Elevate from "./pages/Elevate";
import Proof from "./pages/Proof";
import PrintOptions from "./pages/PrintOptions";
import Cart from "./pages/Cart";
import Copay from "./pages/Copay";
import Vouchers from "./pages/Vouchers";
import OrderReview from "./pages/OrderReview";
import OrderThankYou from "./pages/OrderThankYou";
import MyOrders from "./pages/MyOrders";
import MyWallet from "./pages/MyWallet";
import MyDesigns from "./pages/MyDesigns";
import MyLists from "./pages/MyLists";
import Shipping from "./pages/Shipping";
import Payment from "./pages/Payment";
import MySubscriptions from "./pages/MySubscriptions";
import Products from "./pages/Products";
import { SessionProvider } from "./contexts/SessionContext";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="full-height">
      <BrowserRouter>
        <Header />
        <Container className="content-wrap mb-4">
          <SiteContextProvider>
            <SessionProvider>
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/:id" element={<Products />} />
                <Route path="/properties" element={<Properties />} />
                <Route path="/select-layout" element={<SelectLayout />} />
                <Route path="/elevate" element={<Elevate />} />
                <Route path="/print-options" element={<PrintOptions />} />
                <Route path="/proof" element={<Proof />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/" element={<Shipping />} />
                <Route path="/" element={<Copay />} />
                <Route path="/" element={<Vouchers />} />
                <Route path="/" element={<Payment />} />
                <Route path="/" element={<OrderReview />} />
                <Route path="/" element={<OrderThankYou />} />

                <Route path="/" element={<MyOrders />} />
                <Route path="/" element={<MyWallet />} />
                <Route path="/" element={<MyDesigns />} />
                <Route path="/" element={<MyLists />} />
                <Route path="/" element={<MySubscriptions />} />
              </Routes>
            </SessionProvider>
          </SiteContextProvider>
        </Container>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
