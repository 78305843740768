import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductItem from "../components/Product";

interface Product {
  id: number;
  categoryId: number;
  name: string;
  description: string;
  badge: string;
}

const Products: React.FC = () => {
  const { id } = useParams<{ id?: string }>();

  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const categoryId = parseInt(id, 10);

    fetch("/data/products.json")
      .then((response) => response.json())
      .then((data: Product[]) => {
        const filteredProducts = data.filter(
          (product) => product.categoryId === categoryId
        );
        setProducts(filteredProducts);
      });
  }, [id]);

  if (!id) {
    return <div>No category selected.</div>;
  }

  return (
    <>
      <h1>Products in Category {id}</h1>
      <div className="d-flex flex-wrap">
        {products.map((product) => (
          <div key={product.id}>
            <ProductItem product={product} />{" "}
            {/* Using the new Product component */}
          </div>
        ))}
      </div>
    </>
  );
};

export default Products;
