// SelectLayout.tsx
import React from "react";
import { useSessionContext } from "../contexts/SessionContext";
import { useNavigate } from "react-router-dom";

const SelectLayout: React.FC = () => {
  const { session } = useSessionContext();
  const navigate = useNavigate();

  const handleSelect = () => {
    navigate("/elevate");
  };

  return (
    <>
      <h1 className="mb-5">Select Layout</h1>
      <div className="d-none">
        <p>Session ID: {session.sessionId}</p>
        <p>
          Selected Product ID:{" "}
          {session.productId !== null ? session.productId : "None selected"}
        </p>
        <p>
          Selected Property ID:{" "}
          {session.mlsId !== null ? session.mlsId : "None selected"}
        </p>
      </div>
      <div className="row">
        <div className="col-12">
          <h5 className="mb-3 text-primary">Front Cover</h5>
        </div>

        <div className="col-md-4 col-lg-3">
          <div className="card my-3">
            <img
              src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
              className="card-image-top"
              alt="thumbnail"
            />

            <div className="card-body">
              <p className="card-text">
                <button onClick={handleSelect} style={{ marginTop: "20px" }}>
                  Select
                </button>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card my-3">
            <img
              src="https://dummyimage.com/450x300/dee2e6/6c757d.jpgg"
              className="card-image-top"
              alt="thumbnail"
            />

            <div className="card-body">
              <p className="card-text">
                <button>Select</button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-12">
          <h5 className="mb-3 text-primary">Back</h5>
        </div>

        <div className="col-md-4 col-lg-3">
          <div className="card my-3">
            <img
              src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
              className="card-image-top"
              alt="thumbnail"
            />

            <div className="card-body">
              <p className="card-text">
                <button>Select Option 1 Back</button>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card my-3">
            <img
              src="https://dummyimage.com/450x300/dee2e6/6c757d.jpgg"
              className="card-image-top"
              alt="thumbnail"
            />

            <div className="card-body">
              <p className="card-text">
                <button>Select Option 2 Back</button>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <div className="card my-3">
            <img
              src="https://dummyimage.com/450x300/dee2e6/6c757d.jpgg"
              className="card-image-top"
              alt="thumbnail"
            />

            <div className="card-body">
              <p className="card-text">
                <button>Select Option 3 Back</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectLayout;
