import React from "react";
import { Link } from "react-router-dom";
import { useSessionContext } from "../contexts/SessionContext";

interface ProductProps {
  product: {
    id: number;
    categoryId: number;
    name: string;
    description: string;
    badge: string;
  };
}

const Product: React.FC<ProductProps> = ({ product }) => {
  const { setProductId } = useSessionContext();

  const handleProductSelect = () => {
    setProductId(product.id);
    //add skipping property selection
  };

  return (
    <>
      <div className="col mb-5 pe-5">
        <div className="card h-100">
          {product.badge && (
            <div
              className="badge bg-dark text-white position-absolute"
              style={{ top: "0.5rem", right: "0.5rem" }}
            >
              {product.badge}
            </div>
          )}
          <img
            className="card-img-top"
            src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
            alt="..."
          />
          <div className="card-body p-4">
            <div className="text-center">
              <h5 className="fw-bolder">{product.name}</h5>
              {product.description}
            </div>
          </div>
          <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
            <div className="text-center">
              <Link to="/properties" onClick={handleProductSelect}>
                Select Product
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
